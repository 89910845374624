import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 706.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,706.000000) scale(0.100000,-0.100000)">
					<path d="M5240 6610 c-81 -6 -172 -24 -215 -40 -11 -4 -51 -18 -90 -30 -113
-36 -235 -97 -360 -179 -90 -59 -179 -140 -295 -267 -43 -47 -152 -209 -184
-273 -210 -418 -214 -911 -11 -1321 50 -101 78 -153 86 -160 3 -3 17 -22 31
-43 25 -37 26 -37 31 -14 9 33 -7 124 -25 145 -42 47 -134 274 -167 412 -38
159 -40 487 -3 612 78 264 140 393 267 558 57 73 159 179 215 221 25 18 47 36
50 39 3 3 46 32 95 63 360 226 833 275 1232 127 110 -41 303 -149 351 -197 7
-7 17 -13 20 -13 12 0 177 -162 224 -220 138 -170 241 -387 284 -597 25 -122
30 -389 10 -509 -31 -182 -101 -372 -192 -516 l-26 -41 19 -41 20 -41 45 70
c91 141 141 258 190 445 26 102 30 137 35 300 4 160 2 201 -16 299 -111 604
-561 1065 -1164 1192 -103 22 -320 31 -457 19z m193 -7 c-7 -2 -21 -2 -30 0
-10 3 -4 5 12 5 17 0 24 -2 18 -5z m-105 -10 c-10 -2 -26 -2 -35 0 -10 3 -2 5
17 5 19 0 27 -2 18 -5z m95 -30 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24
-2 18 -5z m-226 -19 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-904 -519 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m-306 -587
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-14 -463 c-3 -9 -8 -14
-10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m14 -137 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z"/>
					<path d="M6170 5789 c-41 -4 -125 -7 -187 -8 -61 0 -122 -5 -135 -10 -15 -5
-32 -26 -43 -53 -10 -23 -26 -61 -36 -83 -9 -22 -46 -110 -82 -195 -36 -85
-88 -210 -117 -278 -52 -122 -52 -123 -36 -157 65 -136 104 -368 92 -545 l-6
-95 29 61 c16 33 32 72 36 85 4 13 22 56 40 94 18 39 56 124 85 190 29 66 65
148 81 183 16 35 29 66 29 68 0 3 11 29 24 57 13 29 59 133 101 232 43 99 91
208 106 242 16 34 40 91 55 125 14 35 31 69 36 76 15 17 12 18 -72 11z"/>
					<path d="M4584 5748 c33 -78 60 -138 92 -206 19 -40 34 -75 34 -77 0 -2 13
-32 29 -67 16 -35 54 -121 86 -193 31 -71 68 -155 82 -185 14 -30 31 -71 38
-90 7 -19 22 -53 33 -75 11 -22 26 -56 35 -75 8 -19 31 -70 51 -113 20 -44 36
-80 36 -82 0 -2 30 -71 66 -152 113 -252 136 -307 142 -338 3 -16 13 -36 23
-43 11 -7 19 -18 19 -24 0 -22 84 -188 91 -181 11 12 59 177 76 263 19 96 21
295 4 385 -21 113 -42 178 -104 315 -16 36 -54 121 -84 190 -30 69 -68 154
-85 190 -17 36 -52 115 -79 175 -26 61 -64 143 -83 183 -20 40 -36 76 -36 81
0 21 -60 128 -72 128 -7 1 -89 6 -183 12 -246 16 -228 17 -211 -21z m424 -63
c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z"/>
					<path d="M4805 3450 c-3 -5 -16 -10 -28 -10 -48 0 -149 -60 -216 -128 -72 -74
-117 -150 -131 -227 -5 -27 -12 -62 -16 -77 -3 -15 1 -66 11 -113 36 -184 133
-301 310 -375 74 -30 84 -32 205 -32 93 0 137 4 164 16 l38 16 -58 64 -59 65
-72 0 c-159 2 -281 91 -318 235 -19 73 -19 119 1 186 56 190 269 284 446 197
57 -28 128 -91 128 -114 0 -7 4 -13 8 -13 18 0 36 -80 39 -164 l2 -90 68 -68
c37 -37 71 -68 76 -68 15 0 46 85 57 157 21 133 -28 292 -118 388 -48 50 -89
85 -101 85 -5 0 -11 3 -13 8 -6 14 -70 42 -109 48 -22 4 -42 11 -45 15 -7 13
-261 11 -269 -1z"/>
					<path d="M1393 3378 c4 -23 24 -77 104 -288 22 -58 74 -196 116 -306 42 -111
83 -218 91 -238 l15 -36 140 0 140 0 15 33 c8 17 40 102 71 187 31 85 68 184
81 220 14 36 36 94 49 130 13 36 42 112 64 170 63 169 73 150 -71 150 l-124 0
-26 -87 c-33 -110 -105 -345 -148 -481 -43 -138 -53 -135 -103 28 -21 69 -63
204 -94 300 -30 96 -59 190 -64 208 l-11 32 -125 0 c-125 0 -125 0 -120 -22z"/>
					<path d="M2415 3377 c-3 -12 -5 -54 -3 -92 l3 -70 145 -5 145 -5 3 -350 2
-350 125 3 125 3 0 349 0 349 148 3 147 3 3 93 3 92 -420 0 -421 0 -5 -23z"/>
					<path d="M3420 3120 c0 -275 6 -345 36 -424 32 -83 105 -150 199 -182 38 -13
85 -18 200 -18 135 0 156 2 210 23 79 31 154 106 186 187 23 57 24 69 27 377
l3 317 -125 0 -125 0 -3 -314 c-3 -300 -4 -316 -24 -342 -42 -57 -69 -69 -154
-69 -72 0 -81 2 -117 31 -57 46 -61 75 -62 407 l-1 287 -125 0 -125 0 0 -280z"/>
					<path d="M5600 2950 l0 -450 125 0 125 0 2 193 3 192 183 3 182 2 0 75 0 75
-182 2 -183 3 -3 75 c-5 131 -25 120 218 120 l210 0 0 80 0 80 -340 0 -340 0
0 -450z"/>
					<path d="M6470 2950 l0 -450 130 0 130 0 0 195 0 195 180 0 180 0 0 75 0 75
-180 0 -180 0 0 100 0 100 210 0 210 0 0 80 0 80 -340 0 -340 0 0 -450z"/>
					<path d="M7340 2950 l0 -450 375 0 375 0 0 90 0 90 -244 0 c-213 0 -245 2
-250 16 -8 20 -8 168 0 188 5 14 35 16 230 16 l225 0 -3 78 -3 77 -220 3
c-160 2 -221 6 -227 15 -9 14 -11 128 -2 151 5 14 36 16 245 16 l239 0 0 80 0
80 -370 0 -370 0 0 -450z"/>
					<path d="M8290 2950 l0 -450 125 0 125 0 0 184 c0 144 3 186 14 195 21 18 94
-3 127 -36 25 -26 83 -127 161 -280 l32 -63 143 0 c131 0 142 1 136 18 -4 9
-31 64 -61 122 -75 145 -123 207 -179 231 -24 10 -43 24 -41 31 2 6 30 20 61
31 226 75 216 407 -14 456 -29 7 -171 11 -339 11 l-290 0 0 -450z m494 284
c37 -18 56 -49 56 -94 0 -82 -50 -110 -200 -110 l-100 0 0 103 c0 57 3 107 7
110 3 4 50 7 104 7 70 0 108 -5 133 -16z"/>
					<path d="M4860 2972 l0 -68 55 -54 c30 -30 55 -52 56 -50 0 3 1 32 2 65 l2 60
69 3 69 3 -53 54 -54 55 -73 0 -73 0 0 -68z"/>
					<path d="M5050 2787 l0 -72 128 -128 127 -127 73 0 c50 0 72 4 73 13 0 6 0 39
-1 72 l-1 60 -127 127 -127 128 -72 0 -73 0 0 -73z"/>
					<path d="M5769 2426 c8 -9 11 -46 9 -103 -3 -87 -3 -88 -29 -91 -21 -2 -29 3
-37 25 -38 97 -70 167 -80 171 -21 7 -17 -32 10 -87 39 -82 44 -108 21 -114
-10 -3 23 -5 72 -5 50 0 84 2 78 5 -9 3 -13 35 -13 109 0 99 -1 104 -21 104
-17 0 -19 -3 -10 -14z"/>
					<path d="M5848 2428 c7 -7 12 -44 12 -98 0 -54 -5 -91 -12 -98 -9 -9 -4 -12
23 -11 18 0 28 3 22 6 -9 3 -13 35 -13 109 0 100 -1 104 -22 104 -17 0 -19 -3
-10 -12z"/>
					<path d="M6020 2430 c0 -5 11 -19 23 -32 13 -13 50 -56 82 -95 31 -40 60 -73
64 -73 16 0 4 41 -20 67 -14 16 -44 54 -68 86 -26 34 -51 57 -62 57 -11 0 -19
-4 -19 -10z"/>
					<path d="M6181 2423 c7 -10 14 -19 15 -21 1 -1 4 6 7 18 4 15 0 20 -15 20 -18
0 -19 -2 -7 -17z"/>
					<path d="M6968 2428 c15 -15 16 -146 2 -182 -9 -25 -8 -26 23 -25 17 0 26 3
20 6 -9 3 -13 35 -13 109 0 100 -1 104 -22 104 -17 0 -19 -3 -10 -12z"/>
					<path d="M7228 2336 c3 -94 1 -105 -15 -109 -10 -3 16 -5 57 -5 51 1 64 3 40
7 l-35 6 -3 103 c-3 98 -4 102 -25 102 -22 0 -22 -2 -19 -104z"/>
					<path d="M7929 2343 c3 -84 1 -98 -15 -110 -15 -11 -11 -13 37 -12 30 1 46 3
37 6 -23 6 -28 31 -25 131 2 76 1 82 -18 82 -19 0 -20 -5 -16 -97z"/>
					<path d="M8022 2430 c19 -50 18 -60 -1 -79 -19 -19 -20 -21 -3 -21 54 0 70 68
22 99 -20 14 -23 14 -18 1z"/>
					<path d="M8520 2430 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
					<path d="M8688 2330 c3 -102 5 -110 23 -110 16 0 18 3 9 19 -5 11 -10 60 -10
110 0 66 -3 91 -12 91 -10 0 -12 -27 -10 -110z"/>
					<path d="M7364 2399 c33 -39 36 -104 5 -143 -14 -18 -16 -26 -7 -26 46 0 80
76 59 133 -13 33 -46 67 -67 67 -12 0 -10 -7 10 -31z"/>
					<path d="M7613 2374 c-17 -17 -17 -126 -1 -142 7 -7 21 -11 33 -11 16 1 16 2
3 6 -14 3 -18 19 -20 83 -2 63 -5 75 -15 64z"/>
					<path d="M6407 2373 c-9 -8 -9 -116 -1 -137 3 -9 16 -16 28 -16 17 0 18 2 6
10 -11 7 -15 28 -16 75 -1 65 -5 81 -17 68z"/>
					<path d="M6494 2298 c25 -76 25 -76 36 -58 5 8 3 24 -6 41 -8 16 -14 39 -14
54 0 18 -5 25 -19 25 -17 0 -17 -3 3 -62z"/>
					<path d="M6560 2342 c0 -10 4 -22 9 -27 5 -6 14 -27 21 -48 7 -22 16 -35 21
-30 4 4 2 26 -6 48 -8 22 -14 48 -15 58 0 23 -30 22 -30 -1z"/>
					<path d="M6640 2350 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
					<path d="M6858 2290 c-3 -67 -2 -70 20 -70 15 0 21 4 16 12 -4 6 -6 29 -6 52
1 22 -1 48 -4 58 -12 37 -22 15 -26 -52z"/>
					<path d="M6915 2350 c3 -5 13 -10 21 -10 8 0 12 5 9 10 -3 6 -13 10 -21 10 -8
0 -12 -4 -9 -10z"/>
					<path d="M7040 2344 c0 -14 3 -14 15 -4 8 7 15 14 15 16 0 2 -7 4 -15 4 -8 0
-15 -7 -15 -16z"/>
					<path d="M7470 2350 c-26 -16 -4 -21 26 -5 23 12 25 15 9 15 -11 0 -27 -4 -35
-10z"/>
					<path d="M7690 2345 c-10 -12 -10 -15 4 -15 9 0 16 4 16 9 0 5 8 12 18 14 13
4 13 5 -4 6 -12 0 -27 -6 -34 -14z"/>
					<path d="M8108 2305 c3 -35 0 -60 -8 -70 -11 -13 -8 -15 21 -14 19 0 28 3 22
6 -9 3 -13 27 -13 69 0 47 -4 64 -13 64 -10 0 -13 -14 -9 -55z"/>
					<path d="M8160 2350 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
					<path d="M8268 2353 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M8370 2356 c0 -15 54 -126 61 -126 13 0 11 17 -6 50 -8 16 -16 41
-17 55 -2 17 -8 25 -20 25 -10 0 -18 -2 -18 -4z"/>
					<path d="M8470 2345 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
					<path d="M8520 2297 c0 -43 -4 -67 -12 -70 -7 -3 1 -6 17 -6 l30 -1 -5 67 c-7
92 -30 99 -30 10z"/>
					<path d="M8610 2340 c-40 -40 -16 -121 36 -119 17 0 16 1 -3 9 -12 5 -25 21
-29 35 -9 37 3 75 29 85 17 7 18 9 5 9 -10 1 -27 -8 -38 -19z"/>
					<path d="M8782 2340 c-48 -45 -17 -122 48 -119 l25 1 -25 7 c-51 14 -63 102
-16 121 14 6 16 9 5 9 -8 1 -25 -8 -37 -19z"/>
					<path d="M8919 2353 c0 -5 0 -32 1 -62 1 -35 -3 -57 -12 -62 -8 -6 -1 -9 22
-9 23 0 30 3 22 9 -9 5 -13 26 -12 57 1 27 1 55 1 62 -1 13 -20 17 -22 5z"/>
					<path d="M8975 2350 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
					<path d="M1360 2340 c0 -7 170 -10 490 -10 320 0 490 3 490 10 0 7 -170 10
-490 10 -320 0 -490 -3 -490 -10z"/>
					<path d="M2385 2340 c-4 -7 148 -10 450 -10 302 0 454 3 450 10 -4 6 -165 10
-450 10 -285 0 -446 -4 -450 -10z"/>
					<path d="M3330 2340 c0 -7 180 -10 520 -10 340 0 520 3 520 10 0 7 -180 10
-520 10 -340 0 -520 -3 -520 -10z"/>
					<path d="M6260 2331 c-28 -54 1 -112 56 -110 24 1 25 3 6 6 -37 7 -57 47 -44
89 11 36 -1 47 -18 15z"/>
					<path d="M6336 2336 c4 -10 -3 -16 -23 -19 -20 -3 -16 -4 15 -6 40 -1 42 0 32
19 -12 23 -32 28 -24 6z"/>
					<path d="M6697 2332 c-10 -10 -17 -31 -17 -45 0 -27 30 -67 51 -67 6 0 3 8 -6
17 -13 12 -16 28 -13 65 3 26 4 48 3 48 -1 0 -9 -8 -18 -18z"/>
					<path d="M6793 2314 c7 -25 7 -41 -2 -60 -9 -20 -9 -25 1 -22 32 11 35 99 4
111 -11 5 -12 -1 -3 -29z"/>
					<path d="M7532 2289 c3 -60 5 -64 28 -67 15 -2 20 0 13 5 -6 5 -13 33 -15 62
-2 34 -8 56 -16 59 -10 3 -12 -11 -10 -59z"/>
					<path d="M7756 2296 c1 -61 8 -76 32 -76 12 0 13 3 5 8 -6 4 -13 32 -15 61 -4
72 -23 78 -22 7z"/>
					<path d="M8216 2325 c-22 -34 -20 -58 7 -83 30 -28 42 -28 23 0 -18 26 -20 61
-6 89 15 28 -5 23 -24 -6z"/>
					<path d="M8311 2329 c6 -13 8 -38 5 -62 -7 -41 -7 -41 14 -22 24 22 26 47 4
80 -20 31 -39 33 -23 4z"/>
					<path d="M8844 2330 c0 -13 7 -20 18 -20 16 0 17 3 8 20 -6 11 -14 20 -18 20
-4 0 -8 -9 -8 -20z"/>
					<path d="M5608 2313 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M8808 2313 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M7033 2266 c15 -18 23 -36 20 -40 -4 -3 11 -6 33 -5 31 0 35 2 18 9
-11 4 -32 22 -47 39 -35 42 -56 40 -24 -3z"/>
					<path d="M7470 2275 c-18 -22 -8 -50 20 -53 24 -3 24 -3 6 11 -15 11 -17 17
-7 35 13 25 0 30 -19 7z"/>
					<path d="M7684 2265 c-10 -24 4 -45 28 -45 17 0 19 3 9 9 -8 5 -12 18 -8 30 6
25 -20 30 -29 6z"/>
					<path d="M5543 2245 l-21 -25 36 0 c20 0 32 4 26 8 -7 4 -13 15 -15 24 -3 15
-7 14 -26 -7z"/>
					<path d="M9036 2253 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z" />
					<path d="M9110 2240 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18 -8
2 -13 -5 -13 -18z"/>
					<path d="M9190 2240 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18 -8
2 -13 -5 -13 -18z"/>
					<path d="M6016 2234 c-18 -13 -17 -14 20 -13 21 0 32 3 24 6 -8 3 -17 9 -20
14 -3 4 -14 1 -24 -7z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
